.menu-item {
    /* font-family: 'Anonymous Pro', monospace; */
    /* font-family: 'Oxygen Mono', monospace; */
    /* font-family: 'Roboto Mono', monospace; */
    /* font-family: 'Source Code Pro', monospace; */
    /* font-family: 'IBM Plex Mono', monospace; */
    font-family: "Overpass Mono", monospace;
    width: 100%;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.menu-item:last-child {
    border-bottom: 0px;
}

.menu-item img {
    width: 25%;
    height: auto;
    margin-right: 24px;
    border: 8px solid #eeeeee;
}

.menu-item .menu-item-details {
    flex: 1;
}

.menu-item .first-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.menu-item .first-row .name {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    color: #d42127;
}

.menu-item .first-row .price {
    font-size: 18px;
    font-weight: 700;
    text-align: right;
}

.menu-item .description {
    font-size: 15px;
    font-weight: 400;
    color: #454545;
    margin-top: 8px;
}

@media (max-width: 575px) {
    .menu-item {
        padding-bottom: 24px;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
    }

    .menu-item img {
        width: 100%;
        height: auto;
        margin-right: 0px;
        margin-bottom: 24px;
        box-sizing: border-box;
    }

    .menu-item .first-row .name {
        font-size: 18px;
    }

    .menu-item .first-row .price {
        font-size: 16px;
    }

    .menu-item .description {
        font-size: 13px;
        margin-top: 8px;
    }
}
