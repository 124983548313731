.landing {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.landing .landing-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 110vh;
    background-image: url("https://southern-coop-wings.s3.us-east-2.amazonaws.com/background-1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0px 64px 128px -24px rgba(0, 0, 0, 0.75);
    filter: brightness(95%);
}

.landing .landing-banner {
    padding: 16px 32px;
    background: white;
    font-family: "KG HAPPY Regular";
    font-size: 58px;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    z-index: 1;
}

.landing .landing-banner .next-line {
    display: none;
}

.landing .landing-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.landing ul {
    list-style-type: none;
    padding: 0;
}

.landing .landing-actions .landing-actions-button {
    background-color: #d42127;
    color: #fff;
    font-size: 16px;
    height: 72px;
    width: 156px;
    margin: 0px 2px;
}

.landing .landing-actions .landing-actions-button:hover {
    background-color: #000;
}

.home {
    width: 100vw;
    overflow: hidden;
    padding-bottom: 64px;
}

.home-section {
    padding: 144px 164px 0px 164px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.home-section .home-section-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 36px;
}

.home-section .home-section-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 36px;
}

.home-section .home-section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-section .home-section-left img {
    transform: rotate(-2deg);
}

.home-section .home-section-right img {
    transform: rotate(2deg);
}

.home-section .subtitle {
    font-family: "KG HAPPY Solid Regular";
    font-size: 28px;
    text-align: center;
    background-color: #d42127;
    color: white;
    padding: 8px 12px;
    margin-bottom: 16px;
}

.home-section .title {
    font-family: "KG HAPPY Regular";
    font-size: 36px;
    text-align: center;
    background-color: #fff;
    padding: 8px;
    margin-bottom: 16px;
}

.home-section .info {
    font-family: "KG HAPPY Solid Regular";
    font-size: 14px;
    background-color: #121212;
    color: white;
    padding: 48px 36px;
}

.home-section img {
    width: 90%;
    height: auto;
    border: 16px solid #eeeeee;
}

/* XL */

@media (max-width: 1199px) {
    .landing .landing-banner {
        font-size: 50px;
    }
    .home-section {
        padding: 128px 96px 0px 96px;
    }
}

/* LG */

@media (max-width: 991px) {
    .landing .landing-banner .next-line {
        display: initial;
    }
    .landing .landing-actions .landing-actions-button {
        font-size: 16px;
        height: 64px;
        width: 140px;
        margin: 0px 2px;
    }
    .home-section {
        padding: 96px 40px 0px 40px;
    }
    .home-section .home-section-left {
        margin-right: 24px;
    }
    .home-section .home-section-right {
        margin-left: 24px;
    }
}

/* MD */

@media (max-width: 767px) {
    .landing .landing-banner {
        margin-top: 64px;
        padding: 16px 24px;
        font-size: 42px;
    }
    .landing .landing-actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .landing .landing-actions .landing-actions-button {
        width: 148px;
        margin: 0px 0px 6px 0px;
    }
    .home-section {
        padding: 96px 24px 0px 24px;
    }
    .home-section .home-section-left {
        margin-right: 8px;
    }
    .home-section .home-section-right {
        margin-left: 8px;
    }
    .home-section .info {
        padding: 32px 24px;
    }
    .home-section img {
        width: 100%;
        border: 8px solid #eeeeee;
        box-sizing: border-box;
    }
}

/* SM */

@media (max-width: 575px) {
    .landing .landing-banner {
        margin: 0px 16px;
        padding: 12px 16px 10px 16px;
        font-size: 30px;
        white-space: normal;
    }
    .landing .landing-banner .next-line {
        display: none;
    }
    .landing .landing-actions .landing-actions-button {
        font-size: 15px;
        height: 56px;
        width: 124px;
        margin: 0px 0px 4px 0px;
    }
    .home-section {
        padding: 64px 16px 0px 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .home-section .home-section-left {
        width: 100%;
        margin-right: 0px;
    }
    .home-section .home-section-right {
        width: 100%;
        margin-left: 0px;
    }
    .home-section .home-section-left img {
        transform: rotate(0deg);
        margin-bottom: 24px;
    }
    .home-section .home-section-right img {
        transform: rotate(0deg);
    }
    .home-section .subtitle {
        font-size: 28px;
        margin-bottom: 16px;
    }
    .home-section .title {
        font-size: 24px;
        padding: 4px 8px;
        margin-bottom: 16px;
    }
    .home-section .info {
        font-size: 12px;
    }
}
