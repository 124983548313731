.modal {
    font-family: "KG HAPPY Solid Regular";
    font-weight: 400;
    border: 2px solid black;
}

.modal .modal-header {
    font-size: 18px;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.modal .modal-header .close-icon:hover {
    cursor: pointer;
}

.modal .button {
    height: 56px;
    color: white;
    background-color: #d42127;
    margin-bottom: 8px;
}

.modal .button:hover {
    background-color: black;
}

.modal .button .button-icon {
    font-size: 20px;
    margin-right: 12px;
}

.modal .helper {
    margin-top: 24px;
    font-size: 12px;
    opacity: 0.7;
}
