.header {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0px 96px;
    margin-top: 24px;
    z-index: 3;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    box-sizing: border-box;
    height: 99px;
}

.header img {
    width: 200px;
    user-select: none;
    position: absolute;
    top: 0;
    transition: opacity 0.25s;
}

.header .header-left .visible {
    opacity: 1;
    z-index: 2;
}

.header .header-left .hidden {
    opacity: 0;
    z-index: 0;
}

.header .header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.header .header-icon {
    font-size: 20px;
    color: white;
    margin-right: 12px;
}

.header .header-order {
    background-color: #000;
    height: 60px;
    color: white;
}

.header .header-call {
    background-color: #000;
    height: 60px;
    color: white;
    margin: 0px 2px;
}

.header .header-order:hover,
.header-call:hover {
    background-color: #d42127;
    color: white;
}

.header .header-menu {
    background-color: #d42127;
    height: 60px;
    padding: 0px 18px;
}

/* XL */

@media (max-width: 1199px) {
    .header {
        padding: 0px 48px;
    }
}

/* LG */

@media (max-width: 991px) {
    .header {
        padding: 0px 24px;
    }
}

/* MD */

@media (max-width: 767px) {
    .header {
        padding: 0px 16px;
        margin-top: 16px;
        height: 77px;
    }
    .header img {
        width: 156px;
    }
}

/* SM */

@media (max-width: 575px) {
    .header {
        padding: 0px 16px;
        margin-top: 16px;
        height: 60px;
    }
    .header img {
        width: 120px;
    }
    .header .header-icon {
        font-size: 16px;
        margin-right: 8px;
    }
    .header .header-order {
        height: 48px;
        font-size: 10px;
        padding: 0px 10px;
    }
    .header .header-call {
        height: 48px;
        font-size: 10px;
        padding: 0px 10px;
    }
    .header .header-menu {
        height: 48px;
        padding: 0px 12px;
    }
}
