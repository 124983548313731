.socials {
    margin-top: 96px;
    padding: 72px 96px;
}

.socials .socials-box {
    box-sizing: border-box;
    background-color: #fff;
    width: 100%;
    border: 2px solid #000;
    display: flex;
    flex-direction: row;
    font-family: "KG HAPPY Solid Regular";
}

.socials .socials-box .title {
    font-size: 28px;
    text-align: center;
    margin-bottom: 16px;
}

.socials .socials-box .feedback {
    width: 50%;
    border-right: 1px solid #000;
    padding: 24px 24px 32px 24px;
}

.socials .socials-box .feedback .ratings {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.socials .socials-box .feedback .ratings .google,
.yelp {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.socials .socials-box .feedback .ratings .star-icon {
    font-size: 28px;
    color: #fbc02d;
    padding-bottom: 4px;
    margin: 0 4px;
}

.socials .socials-box .feedback .feedback-actions {
    display: flex;
    flex-direction: row;
}

.socials .socials-box .feedback .feedback-actions a {
    width: 100%;
    margin: 0px 16px;
}

.socials .socials-box .feedback .feedback-actions .feedback-button {
    padding: 24px 0px;
    width: 100%;
    background-color: #d42127;
    color: #fff;
    font-size: 14px;
    text-align: center;
}

.socials .socials-box .feedback .feedback-actions .feedback-button:hover {
    background-color: #000;
}

.socials .socials-box .get-social {
    width: 50%;
    border-left: 1px solid #000;
    padding: 24px 24px 32px 24px;
    display: flex;
    flex-direction: column;
}

.socials .socials-box .get-social .social-icons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.socials .socials-box .get-social .social-icon {
    height: 24px;
    width: 24px;
    font-size: 25px;
    background-color: #000;
    color: white;
    padding: 16px;
    margin: 0px 12px;
    border-radius: 50%;
    transition: all 0.15s;
}

.socials .socials-box .get-social .social-icon:hover {
    background-color: #d42127;
    cursor: pointer;
}

.footer {
    background-color: #121212;
    width: 100%;
    padding: 0px 96px;
    box-sizing: border-box;
}

.footer .links {
    padding: 64px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.footer .links .links-column {
    width: 33%;
    color: #fff;
    font-family: "KG HAPPY Solid Regular";
    font-size: 13px;
    text-align: center;
    line-height: 24px;
}

.footer .links .links-column .title {
    color: #fff;
    font-family: "KG HAPPY Regular";
    font-size: 21px;
    margin-bottom: 24px;
}

.footer .divider {
    border-top: 1px solid #333333;
}

.footer .signature {
    padding: 32px 0px;
    font-family: "KG HAPPY Solid Regular";
    font-size: 11px;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

/* Extra large devices (large desktops, 1200px and up) */

@media (max-width: 1199px) {
    .socials {
        padding: 72px 48px;
    }
    .footer {
        padding: 0px 48px;
    }
}

/* Large devices (desktops, 992px and up) */

@media (max-width: 991px) {
    .socials {
        padding: 48px 24px;
    }
    .socials .socials-box .feedback {
        width: 60%;
        padding: 24px 8px 32px 8px;
    }
    .socials .socials-box .feedback .feedback-actions a {
        margin: 0px 4px;
    }
    .socials .socials-box .get-social {
        width: 40%;
    }
    .socials .socials-box .get-social .social-icon {
        margin: 0px 4px;
    }
    .footer {
        padding: 0px 24px;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (max-width: 767px) {
    .socials {
        padding: 0px 0px;
    }
    .socials .socials-box {
        display: flex;
        flex-direction: column;
    }
    .socials .socials-box .feedback {
        width: 100%;
        border-right: 0px;
        border-bottom: 1px solid #000;
        padding: 24px 8px 32px 8px;
        box-sizing: border-box;
    }
    .socials .socials-box .feedback .feedback-actions a {
        margin: 0px 16px;
    }
    .socials .socials-box .get-social {
        width: 100%;
        border-left: 0px;
        border-top: 1px solid #000;
        padding: 24px 24px 32px 24px;
        box-sizing: border-box;
    }
    .socials .socials-box .get-social .social-icon {
        margin: 0px 12px;
    }
    .footer {
        padding: 0px 16px;
    }
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 575px) {
    .socials .socials-box .title {
        font-size: 24px;
    }
    .socials .socials-box .feedback .ratings {
        font-size: 13px;
        margin-bottom: 20px;
    }
    .socials .socials-box .feedback .ratings .star-icon {
        font-size: 20px;
    }
    .socials .socials-box .feedback .feedback-actions a {
        margin: 0px 8px;
    }
    .socials .socials-box .feedback .feedback-actions .feedback-button {
        padding: 16px 0px;
        font-size: 12px;
    }
    .socials .socials-box .get-social .social-icon {
        padding: 12px;
        margin: 0px 8px;
    }
    .footer .links {
        padding: 48px 0px 8px 0px;
        display: flex;
        flex-direction: column;
    }
    .footer .links .links-column {
        width: 100%;
        font-size: 12px;
        line-height: 22px;
        margin-bottom: 32px;
    }
    .footer .links .links-column .title {
        font-size: 18px;
        margin-bottom: 16px;
    }
    .footer .signature {
        padding: 16px 0px;
        font-size: 10px;
    }
}
