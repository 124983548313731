/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'KG HAPPY Regular';
font-style: normal;
font-weight: normal;
src: local('KG HAPPY Regular'), url('KGHAPPY.woff') format('woff');
}


@font-face {
font-family: 'KG HAPPY Shadows Regular';
font-style: normal;
font-weight: normal;
src: local('KG HAPPY Shadows Regular'), url('KGHAPPYShadows.woff') format('woff');
}


@font-face {
font-family: 'KG HAPPY Solid Regular';
font-style: normal;
font-weight: normal;
src: local('KG HAPPY Solid Regular'), url('KGHAPPYSolid.woff') format('woff');
}