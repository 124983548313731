.contact {
    margin: 0px 164px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 72px;
}

.contact .contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact .contact-info .contact-info-box {
    font-family: 'KG HAPPY Solid Regular';
    font-size: 14px;
    background-color: #121212;
    color: white;
    padding: 48px 36px;
    text-align: center;
}

.contact .contact-info .contact-info-box .contact-info-box-title {
    font-family: 'KG HAPPY Regular';
    font-size: 32px;
    color: #d42127;
    margin-bottom: 8px;
}

.contact .contact-info .contact-info-box .contact-info-box-content {
    margin-bottom: 24px;
}

.contact .contact-info .contact-info-box .contact-info-box-content:last-child {
    margin-bottom: 0px;
}

.contact .contact-image {
    text-align: center;
}

.contact .contact-image img {
    box-sizing: border-box;
    width: 90%;
    height: auto;
    border: 16px solid #eeeeee;
    transform: rotate(2deg);
}

/* XL */

@media (max-width: 1199px) {
    .contact {
        margin: 0px 96px;
        grid-column-gap: 64px;
    }
}

/* LG */

@media (max-width: 991px) {
    .contact {
        margin: 0px 24px;
        grid-template-columns: 1fr;
        grid-row-gap: 48px;
    }
    .contact .contact-image img {
        width: 80%;
        max-width: 512px;
        border: 8px solid #eeeeee;
        transform: rotate(0deg);
    }
}

/* MD */

@media (max-width: 767px) {
    .contact {
        margin: 0px 16px;
    }
}

/* SM */

@media (max-width: 575px) {
    .contact {
        grid-row-gap: 24px;
    }
    .contact .contact-info .contact-info-box {
        font-size: 12px;
        padding: 24px 24px;
    }
    .contact .contact-info .contact-info-box .contact-info-box-title {
        font-size: 24px;
        margin-bottom: 8px;
    }
    .contact .contact-image img {
        width: 100%;
        max-width: none;
    }
}