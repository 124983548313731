/* MENU OPTIONS */

.menu-alert {
    text-align: center;
    margin-bottom: 24px;
    font-family: "KG HAPPY Solid Regular";
    font-size: 14px;
}

.menu-options {
    box-sizing: border-box;
    margin: 0px 96px;
    height: 80px;
    background-color: #000;
    border: 2px solid #eeeeee;
    display: flex;
}

.menu-options .menu-options-scrollable {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    scroll-snap-type: x proximity;
}

.menu-options .menu-option {
    color: #fff;
    flex: 1 1;
    border-right: 2px solid #eeeeee;
    text-align: center;
    scroll-snap-align: left;
}

.menu-options .menu-option:last-child {
    border-right: 0px;
}

.menu-options .menu-option:hover {
    background-color: #d42127;
}

.menu-options .selected {
    background-color: #d42127;
}

/* MENU OPTIONS INDICATOR */

.menu-options .menu-options-scroll-indicator-left {
    background-color: #eeeeee;
    border-left: 1px solid #eeeeee;
    border-right: 4px solid #eeeeee;
    height: 100%;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none;
}

.menu-options .menu-options-scroll-indicator-right {
    background-color: #eeeeee;
    border-left: 4px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    height: 100%;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none;
}

/* MENU */

.menu {
    margin: 24px 96px 0px 96px;
    box-sizing: border-box;
    border: 2px solid #000;
    background-color: #fff;
    padding: 36px 72px;
}

.menu .menu-selected-option {
    text-align: center;
    text-transform: uppercase;
    font-family: "KG HAPPY Regular";
    font-size: 32px;
    margin-bottom: 32px;
}

.menu .menu-selected-option-description {
    padding: 24px 10%;
    margin-bottom: 48px;
    font-family: "Overpass Mono", monospace;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    border: 1px solid #eeeeee;
}

.menu .addon {
    text-align: center;
    padding: 24px 0px;
    margin: 48px 0px;
    border: 1px solid #eeeeee;
}

.menu .addon .addon-name {
    display: inline-block;
    font-family: "KG HAPPY Solid Regular";
    font-size: 23px;
    color: #d42127;
    white-space: nowrap;
    transform: translate(-50%, -43px);
    background-color: #fff;
    padding: 0px 8px;
    position: absolute;
    text-transform: uppercase;
}

.menu .addon .addon-option {
    font-family: "Overpass Mono", monospace;
    font-size: 15px;
    font-weight: 300;
    color: #454545;
}

.menu .addon .addon-option-icon {
    color: #00a6da;
}

.menu .addon .addon-option-grid {
    display: grid;
    grid-template-rows: repeat(18, auto);
    grid-template-columns: repeat(3, calc(100% / 3));
    grid-auto-flow: column;
}

.menu .addon .dry {
    font-weight: 700;
    text-decoration: underline;
    color: #d42127;
}

/* WINGS */

.menu .wing-type {
    text-align: center;
    margin: 48px 0px;
    padding-top: 36px;
    border: 6px double #00a6da;
}

.menu .wing-type-name {
    display: inline-block;
    font-family: "KG HAPPY Solid Regular";
    font-size: 23px;
    color: #00a6da;
    white-space: nowrap;
    transform: translate(-50%, -58px);
    background-color: #fff;
    padding: 0px 8px;
    position: absolute;
    text-transform: uppercase;
}

.menu .wing-meal-type {
    font-family: "KG HAPPY Solid Regular";
    text-align: center;
    font-size: 18px;
}

.menu .wing-prices-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-family: "Overpass Mono", monospace;
    margin: 24px 0px 48px 0px;
}

.menu .wing-prices-wrapper .size {
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    color: #d42127;
}

.menu .wing-prices-wrapper .price {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    /* color: #454545; */
}

.menu .wing-images .wing-images-row-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 24px;
    margin-bottom: 48px;
}

.menu .wing-images .wing-images-row-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 48px;
}

.menu .wing-images .wing-images-row-1 img {
    width: 28%;
    height: auto;
    border: 8px solid #eeeeee;
}

.menu .wing-images .wing-images-row-2 img {
    width: 28%;
    height: auto;
    border: 8px solid #eeeeee;
}

.menu .wing-images .wing-1 {
    transform: rotate(-3deg);
}

.menu .wing-images .wing-2 {
    transform: rotate(3deg);
}

.menu .wing-images .wing-3 {
    transform: rotate(-3deg);
}

.menu .wing-images .wing-4 {
    transform: rotate(-5deg);
    margin-right: 24px;
}

.menu .wing-images .wing-5 {
    transform: rotate(5deg);
    margin-left: 24px;
}

/* TENDERS */

.tenders-image {
    text-align: center;
    padding-top: 24px;
    margin-bottom: 24px;
}

.tenders-image img {
    width: 40%;
    border: 8px solid #eeeeee;
    transform: rotate(-2deg);
}

/* Catering sandwiches */

.catering-sandwiches {
    border: 1px solid #eeeeee;
    text-align: center;
    margin-bottom: 48px;
    padding: 24px 10%;
    font-family: "Overpass Mono", monospace;
}

.catering-sandwiches .catering-sandwiches-name {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    color: #d42127;
    margin-top: 16px;
}

.catering-sandwiches .catering-sandwiches-desc {
    font-size: 15px;
    font-weight: 300;
    color: #454545;
    margin: 8px 0px 32px 0px;
}

/* Catering salad */

.catering-salad-item {
    font-family: "Overpass Mono", monospace;
    width: 100%;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #eeeeee;
}

.catering-salad-item:last-child {
    border: 0px;
}

.catering-salad-item .catering-salad-name {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    color: #d42127;
}

.catering-salad-item .catering-salad-desc {
    font-size: 15px;
    font-weight: 300;
    color: #454545;
    margin: 8px 0px;
}

.catering-salad-item .catering-salad-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
}

/* XL */

@media (max-width: 1199px) {
    .menu-options {
        margin: 0px 48px;
    }
    .menu {
        margin: 24px 48px 0px 48px;
    }
}

/* LG */

@media (max-width: 991px) {
    .menu-options {
        margin: 0px 24px;
    }
    .menu {
        margin: 24px 24px 0px 24px;
        padding: 36px 48px;
    }
}

/* MD */

@media (max-width: 767px) {
    .menu-options {
        margin: 0px 16px;
    }
    .menu-options .menu-options-scroll-indicator-left {
        display: flex;
    }
    .menu-options .menu-options-scroll-indicator-right {
        display: flex;
    }
    .menu {
        margin: 16px 16px 0px 16px;
        padding: 24px 32px;
    }
    .menu .addon .addon-option-grid {
        display: grid;
        grid-template-rows: repeat(27, auto);
        grid-template-columns: repeat(2, calc(100% / 2));
        grid-auto-flow: column;
    }
    .menu .wing-images .wing-images-row-1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* padding-top: 24px; */
        margin-bottom: 0px;
    }
    .menu .wing-images .wing-images-row-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
    }
    .menu .wing-images .wing-images-row-1 img {
        width: 80%;
        height: auto;
        border: 8px solid #eeeeee;
    }
    .menu .wing-images .wing-images-row-2 img {
        width: 80%;
        height: auto;
        border: 8px solid #eeeeee;
    }
    .menu .wing-images .wing-1 {
        transform: rotate(-3deg);
    }
    .menu .wing-images .wing-2 {
        transform: rotate(3deg);
    }
    .menu .wing-images .wing-3 {
        transform: rotate(-3deg);
    }
    .menu .wing-images .wing-4 {
        transform: rotate(3deg);
        margin-right: 0px;
    }
    .menu .wing-images .wing-5 {
        transform: rotate(-3deg);
        margin-left: 0px;
    }
    .tenders-image img {
        width: 100%;
        border: 8px solid #eeeeee;
        transform: rotate(0deg);
        box-sizing: border-box;
    }
}

/* SM */

@media (max-width: 575px) {
    .menu-options {
        height: 56px;
    }
    .menu-options .menu-option {
        font-size: 12px;
    }
    .menu-options .menu-options-scroll-indicator-left {
        font-size: 19px;
    }
    .menu-options .menu-options-scroll-indicator-right {
        font-size: 19px;
    }
    .menu {
        padding: 24px 24px;
    }
    .menu .menu-selected-option {
        font-size: 24px;
        margin-bottom: 24px;
    }
    .menu .menu-selected-option-description {
        padding: 16px;
        margin-bottom: 24px;
        font-size: 13px;
    }
    .menu .addon {
        padding: 24px 16px;
        margin: 32px 0px;
    }
    .menu .addon .addon-name {
        font-size: 18px;
        transform: translate(-50%, -39px);
        padding: 0px 8px;
    }
    .menu .addon .addon-option {
        font-size: 13px;
    }
    .menu .addon .addon-option-grid {
        display: initial;
        text-align: center;
    }
    .menu .wing-type {
        margin: 32px 0px;
        padding-top: 32px;
        border: 4px double #00a6da;
    }
    .menu .wing-type-name {
        font-size: 18px;
        transform: translate(-50%, -48px);
    }
    .menu .wing-meal-type {
        font-size: 15px;
    }
    .menu .wing-prices-wrapper {
        margin: 16px 0px 24px 0px;
        display: grid;
        grid-template-columns: repeat(2, calc(100% / 2));
        grid-auto-flow: row;
    }
    .menu .wing-prices-wrapper .size {
        font-size: 18px;
    }
    .menu .wing-prices-wrapper .price {
        font-size: 16px;
        margin-bottom: 16px;
    }
    .catering-sandwiches {
        margin-bottom: 24px;
        padding: 24px 8px;
    }
    .catering-sandwiches .catering-sandwiches-name {
        font-size: 18px;
        margin-top: 8px;
    }
    .catering-sandwiches .catering-sandwiches-desc {
        font-size: 13px;
        margin: 8px 0px 32px 0px;
    }
    .catering-salad-item {
        padding-bottom: 24px;
        margin-bottom: 24px;
    }
    .catering-salad-item .catering-salad-name {
        font-size: 18px;
    }
    .catering-salad-item .catering-salad-desc {
        font-size: 13px;
        margin: 8px 0px;
    }
    .catering-salad-item .catering-salad-row {
        font-size: 16px;
    }
}
