.subheader {
    margin-top: 164px;
    margin-bottom: 64px;
    /* padding: 0px 96px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.subheader .subheader-text {
    font-family: "KG HAPPY Regular";
    font-size: 72px;
    background-color: #fff;
    padding: 8px 24px;
    margin-bottom: 16px;
    white-space: nowrap;
    user-select: none;
}

.subheader .subheader-button {
    background-color: #d42127;
    color: #fff;
    font-size: 14px;
    padding: 20px 32px;
    transition: all 0.3s ease;
}

.subheader .subheader-button:hover {
    background-color: #000;
    outline: 3px solid #d42127;
}

.subheader .subheader-button .subheader-button-icon {
    font-size: 20px;
    margin-right: 16px;
}

/* XL */

@media (max-width: 1199px) {
}

/* LG */

@media (max-width: 991px) {
}

/* MD */

@media (max-width: 767px) {
    .subheader {
        margin-top: 144px;
        margin-bottom: 64px;
    }
    .subheader .subheader-text {
        font-size: 58px;
    }
}

/* SM */

@media (max-width: 575px) {
    .subheader {
        margin-top: 128px;
        margin-bottom: 48px;
    }
    .subheader .subheader-text {
        font-size: 34px;
        padding: 8px 16px;
    }
    .subheader .subheader-button {
        font-size: 12px;
        padding: 12px 24px;
    }

    .subheader .subheader-button:hover {
        background-color: #000;
    }

    .subheader .subheader-button .subheader-button-icon {
        font-size: 18px;
        margin-right: 16px;
    }
}
