.app-menu {
    width: 100vw;
    background-color: #d42127;
    font-family: "KG HAPPY Solid Regular";
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
}

.app-menu-content {
    width: 100%;
    height: 100%;
    border: 4px solid #000;
    padding-top: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.app-menu-content .app-menu-link {
    text-transform: uppercase;
    color: white;
    font-size: 36px;
    margin-bottom: 8px;
    user-select: none;
    transition: all 0.15s;
}

.app-menu-content .app-menu-link:hover {
    cursor: pointer;
    color: #000;
}

/* ACTIONS */

.app-menu-content .app-menu-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 32px 0px 24px 0px;
}

.app-menu-content .app-menu-button {
    height: 64px;
    color: #fff;
    background-color: #000;
    margin: 0px 4px;
    user-select: none;
    width: 164px;
}

.app-menu-content .app-menu-button:hover {
    color: #000;
    background-color: #fff;
}

.app-menu-content .app-menu-button .app-menu-button-icon {
    font-size: 20px;
    margin-right: 12px;
}

/* SOCIALS */

.app-menu-content .app-menu-socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}

.app-menu-content .app-menu-socials .app-menu-social-icon {
    height: 24px;
    width: 24px;
    background-color: #fff;
    color: #000;
    padding: 16px;
    margin: 0px 12px;
    border-radius: 50%;
    transition: all 0.15s;
}

.app-menu-content .app-menu-socials .app-menu-social-icon:hover {
    cursor: pointer;
    background-color: #000;
    color: #fff;
}

/* Signature */

.app-menu-signature {
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    color: #fff;
    padding: 0px 96px 32px 96px;
    user-select: none;
}

.app-menu-signature a:hover {
    color: #000;
}

/* Extra large devices (large desktops, 1200px and up) */

@media (max-width: 1199px) {
    .app-menu-signature {
        padding: 0px 48px 32px 48px;
    }
}

/* Large devices (desktops, 992px and up) */

@media (max-width: 991px) {
    .app-menu-signature {
        padding: 0px 24px 32px 24px;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (max-width: 767px) {
    .app-menu-content .app-menu-link {
        font-size: 30px;
    }
    .app-menu-content .app-menu-actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 8px 0px 8px 0px;
    }
    .app-menu-content .app-menu-button {
        margin: 4px 0px;
    }
    .app-menu-signature {
        padding: 0px 16px 32px 16px;
    }
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 575px) {
    .app-menu-content .app-menu-link {
        font-size: 24px;
        margin-bottom: 4px;
    }
    .app-menu-content .app-menu-button {
        font-size: 12px;
        height: 48px;
        margin: 4px 0px;
        width: 154px;
    }
    .app-menu-content .app-menu-button .app-menu-button-icon {
        font-size: 18px;
    }
    .app-menu-content .app-menu-socials {
        margin-top: 16px;
    }
    .app-menu-content .app-menu-socials .app-menu-social-icon {
        padding: 12px;
        margin: 0px 8px;
    }
    .app-menu-signature {
        font-size: 10px;
        padding: 0px 16px 16px 16px;
    }
}
