.not-found {
    width: 100vw;
    background: rgb(0, 166, 218);
    background: linear-gradient(180deg, rgba(0, 166, 218, 1) 35%, rgba(212, 33, 39, 1) 90%, rgba(217, 23, 23, 1) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.not-found .banner {
    padding: 16px 32px;
    background-color: white;
    font-family: 'KG HAPPY Regular';
    font-size: 45px;
    white-space: nowrap;
}

.not-found .return-button {
    margin-top: 24px;
    height: 64px;
    background-color: #d42127;
    color: #fff;
    font-size: 15px;
}

.not-found .return-button:hover {
    background-color: #000;
}

/* Medium devices (tablets, 768px and up) */

@media (max-width: 767px) {
    .not-found .banner {
        padding: 16px 32px;
        font-size: 36px;
    }
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 575px) {
    .not-found .banner {
        margin: 0px 16px;
        padding: 16px 32px;
        font-size: 32px;
        white-space: normal;
        text-align: center;
    }
}