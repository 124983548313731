@import url("https://fonts.googleapis.com/css?family=Overpass+Mono:300,400,600,700&display=swap");

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
    color: white;
    text-decoration: none;
    transition: all 0.15s;
}

a:hover {
    cursor: pointer;
    color: #d42127;
}

.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: "KG HAPPY Solid Regular";
    font-size: 13px;
    padding: 0px 20px;
    transition: all 0.15s;
}

.button:hover {
    cursor: pointer;
}
