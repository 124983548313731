.banner {
    font-family: "KG HAPPY Solid Regular";
    margin: 72px 25%;
    padding: 36px 48px;
    background-color: #fff;
    border: 2px solid #000;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.banner .title {
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 16px;
}

.banner .button {
    padding: 16px 24px;
    background-color: #d42127;
    color: #fff;
    font-size: 14px;
}

.banner .button:hover {
    background-color: #000000;
}

@media (max-width: 1199px) {
    .banner {
        margin: 72px 15%;
    }
}

@media (max-width: 991px) {
    .banner {
        margin: 48px 24px;
    }
}

@media (max-width: 767px) {
    .banner {
        margin: 0px 0px;
    }
}

@media (max-width: 575px) {
    .banner {
        padding: 24px 16px;
        font-size: 14px;
    }

    .banner .title {
        font-size: 21px;
        margin-bottom: 16px;
    }
}
